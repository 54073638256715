<template>
  <div class="home">
    <img
      src="@/assets/holding.png"
      alt="First choose your background..."
      oncontextmenu="return false;"
    />
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      batchInQueue: false,
    };
  },
  methods: {
    startNewSession() {
      this.$store.commit('loadNewBatch');
      this.$router.push('/preview');
    },
  },
  mounted() {
    if (this.$store.state.batchQueue.length || this.$store.state.currentBatch) {
      this.startNewSession();
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'setInitialBatches' && this.$store.state.currentBatch) {
        this.startNewSession();
      }

      if (mutation.type === 'addBatch' && !this.$store.state.currentBatch) {
        this.startNewSession();
      }
    });
  },
};
</script>

<style scoped>
.home {
  height: 100%;
  position: absolute;
  width: 100%;
}

.home img {
  height: 100%;
  width: 100%;
}
</style>
